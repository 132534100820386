import { react, useState } from 'react'
import {auth} from "../../firebase-config"
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'
import { useNavigate } from 'react-router'


function LoginCom() {

const navigate = useNavigate()
    const [reg, setReg] = useState({email:"", password:"", fullname:""})
    const [login, setLogin] = useState({ email:"", password:""})


    const register = (e) => {
        e.preventDefault()
        createUserWithEmailAndPassword (auth, reg.email, reg.password)
        .then((userCredentials) => {

            console.log("userCredentials", userCredentials)
            //navigate("/creator")
            
              
        }) 
        .catch((err)=>{
            console.log("err", err)
        })
 
    }
    const loginFun = (e)=>{
        e.preventDefault()
        signInWithEmailAndPassword(auth, login.email, login.password)
        .then((userCredentials)=>{
            console.log("Login user", userCredentials)
            navigate("/creator")

        })
        .catch((err)=>{
            console.log("login err", err)
        })

    }
    return (
        <>
            <div className='container mx-auto p-60'>
                <div className="grid grid-cols-1 gap-1 content-center ">

                    <div className='flex justify-center'>
                        <h1 className="text-4xl mb-8 font-extrabold tracking-tight text-gray-900 md:text-5xl lg:text-4xl">SURVEY DESIGNER</h1>


                    </div>
                    <div className='flex justify-center mb-8 text-xl'>
                        Please register if you don't have an account 


                    </div>
                </div>

                <div className="grid h-72 grid-cols-2 gap-4 content-center align-middle">
                    

                    <div className='shadow flex items-center justify-center bg-gray-100 p-8 rounded'>
                        
                        <form className="w-full max-w-sm">
                            <div className="md:flex md:items-center mb-6">
                                <div className="md:w-1/3">
                                    <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                                        Email
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <input onChange={(e)=>setLogin({...login,email:e.target.value}) } value={login.email} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name" type="text" placeholder="example@email.com" />
                                </div>
                            </div>
                            <div className="md:flex md:items-center mb-6">
                                <div className="md:w-1/3">
                                    <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-password">
                                        Password
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <input onChange={(e)=>setLogin({...login,password:e.target.value}) } value={login.password} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-password" type="password" placeholder="******************" />
                                </div>
                            </div>

                            <div className="md:flex md:items-center">
                                <div className="md:w-1/3"></div>
                                <div className="md:w-2/3">
                                    <button onClick={loginFun} className="shadow bg-gray-500 hover:bg-gray-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="submit">
                                        Login
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    {/* REGISTRATION */}

                    <div className="shadow justify-center flex bg-gray-50 p-8 rounded" >
                        <form className="w-full max-w-sm">
                            <div className="md:flex md:items-center mb-6">
                                <div className="md:w-1/3">
                                    <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                                        Email
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <input onChange={(e)=>setReg({...reg,email:e.target.value}) } value={reg.email} placeholder='example@email.com'  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name" type="text"  />
                                </div>
                            </div>
                            <div className="md:flex md:items-center mb-6">
                                <div className="md:w-1/3">
                                    <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                                        Full Name
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <input value={reg.fullname} onChange={(e)=>setReg({...reg, fullname:e.target.value})} placeholder='John Does' className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name" type="text" />
                                </div>
                            </div>

                            <div className="md:flex md:items-center mb-6">
                                <div className="md:w-1/3">
                                    <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-password">
                                        Password
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <input value={reg.password} onChange={(e)=>setReg({...reg, password:e.target.value})} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-password" type="password" placeholder="******************" />
                                </div>
                            </div>

                            <div className="md:flex md:items-center">
                                <div className="md:w-1/3"></div>
                                <div className="md:w-2/3">
                                    <button onClick={register} className="shadow bg-gray-500 hover:bg-gray-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="button">
                                        Register
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>


                </div>
            </div>
        </>
    )
}
export default LoginCom