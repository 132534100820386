import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom"
import LoginCom from './components/login/login';
import Access_Denied from './components/access_denied/access_denied';
import SurveyDesigner from './components/designer/designer';

function App() {
  return (
    <BrowserRouter basename={'/designer'}>
    <Routes>
     <Route path="/login" element={<LoginCom />}/>
     <Route path="/creator" element={<SurveyDesigner/>}/>
     <Route path="/" element={<Access_Denied />}/>
     <Route path="/designer" element={<Access_Denied />}/>
     
    </Routes>
    </BrowserRouter>
    
  );
}

export default App;
