import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import "./designer.css"
import { collection, addDoc } from "firebase/firestore";
import uuid from 'react-uuid';
import { db } from "../../firebase-config";
import { getDocs } from "firebase/firestore"; 

import { useCallback, useEffect, useRef, useState } from "react";




function SurveyDesigner() {

   
    
    var inputVersion = useRef();

    const[version, setVersion] = useState('')
    const[surveys, setSurveys] = useState([])
    const[selected, setSelected] = useState([])

    const creatorOptions = {
        showLogicTab: true,
        isAutoSave: true,
        showLogicTab: true
      };

    const creator = new SurveyCreator(creatorOptions);
    //Automatically save survey definition on changing. Hide "Save" button
    creator.isAutoSave = true;
    //Show state button here
    creator.showState = true;

    useEffect(()=>{
        const Fetchdata = async() => {
            const doc_refs = await getDocs(collection(db, "surveys"))
                 doc_refs.forEach(element => {
                    var data = element.data();
                    console.log(data)
                    setSurveys(arr => [...arr, data]);
     
                });


        }
        Fetchdata()
        console.log(surveys)
    },[])

    const showSurveyJSON = async() =>{
        if (inputVersion.current != ""){
            alert("Survey version " + inputVersion.current + " uploading to databse. Please click ok and wait for another alert message confirming save commit.")
            console.log(creator.text)
            try {
                const docRef = await addDoc(collection(db, "surveys"), {
                  id:uuid(),  
                  version: inputVersion.current,
                  survey:creator.text   
                });
                console.log("Document written with ID: ", docRef.id);
                alert("Survey version " + inputVersion.current + " saved.")
              } catch (e) {
                console.error("Error adding document: ", e);
              }
        }else{
            alert("Please enter version number.")
        }
        
    }
    const handleSelcet = (e) => {
        //alert(e.target.value);
        setSelected(JSON.parse(e.target.value))

        
      };
      creator.JSON = selected

    return (
        <>
        <div className="container mx-auto m-6 ">
            <div>
                
            </div>
            <button 
            onClick={showSurveyJSON} 
            className="shadow bg-red-500 text-white font-bold rounded p-2 float-right mb-5">
                SAVE TO SERVER 
            </button>
            <input value={inputVersion.current} onChange={(e)=>inputVersion.current = e.target.value} type="text" placeholder="Survey Version"  className="bg-gray-50 border p-2 float-right mx-2" />
            
            <select onChange={handleSelcet} className="p-2 round border">
                <option key={0}>Select survey version to edit</option>
        {surveys.map((item, k) =>
            <option key={k} value={item.survey}>
              {item.version}
            </option>
        )}
      </select>
            <br/>
            <br/>
            <br/>
            <hr/>

        </div>
        <SurveyCreatorComponent creator={creator} />
        </>
    )
}

export default SurveyDesigner